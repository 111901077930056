<template>
  <div>
  <v-btn plain to="/gasto"><v-icon left>mdi-arrow-left-circle</v-icon>Volver</v-btn>
  <v-container fluid>
    <v-row>
    <v-spacer></v-spacer>
    <v-btn plain to="/gasto/spclist" ><v-icon left>mdi-file-document-multiple-outline</v-icon>Ver listado SPC</v-btn>
    </v-row>
    <br>
    <v-row align="center" class="list mx-auto">
        <v-col cols="6" md="2">
          <v-select v-model="pageSize" :items="pageSizes" label="Articulos/Página" centered
            @change="handlePageSizeChange" />
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination v-model="page" :length="totalPages" total-visible="10" next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left" @input="handlePageChange" />
        </v-col>
      </v-row>
    <v-row>
    <v-card class="mx-auto" width="95%" tile>
      <v-row>
          <v-card-title>Compras</v-card-title>
          <v-spacer />
          <v-card-subtitle v-text="'Mostrando items '+currentItems+' de '+totalItems" />
        </v-row>
      <v-data-table
        dense
        :headers="headers" 
        :items="oc_s" 
        disable-pagination 
        :hide-default-footer="true">
        <template v-slot:[`item.actions`]="{ item }">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-icon :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mr-2"
                @click="editCode(item.id)">
                mdi-information-variant
              </v-icon>
            </v-hover>
          </template>
        </v-data-table>
        <v-card-actions v-if="oc_s.length > 0">
          <v-pagination v-model="page" :length="totalPages" total-visible="7" next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
        </v-card-actions>

    </v-card>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import CostDataService from '../../services/CostDataService';
export default {
  name:'ordenesdecompra',
  components: {
  },
  data () {
    return {
      totalPages: 0,
      page: 1,
      pageSize: 100,
      pageSizes: [100, 250, 500],
      currentItems: "",
      totalItems: 0,
      oc_s:[],
      headers: [
        { text: "OC", value: "OC", align: "start", sortable: false},
        { text: "Linea", value: "LINEA_OC", sortable: false },
        { text: "Estado", value: "ESTADO", sortable: false },
        { text: "F. OC", value: "FECHA_OC", align:"left",width:"120px" , sortable: false },
        { text: "F. entrega", value: "F_ENTREGA",width:"120px", sortable: false },
        { text: "F. recepción", value: "F_RECEPCION",width:"120px", align:"center", sortable: false },
        { text: "Codigo", value: "CODIGO", align:"start", sortable: false },
        { text: "Descripcion", value: "DESC_CODIGO", align:"start", sortable: false },
        { text: "Cantidad", value: "CANTIDAD", align:"center", sortable: false },
        { text: "$ Costo", value: "COSTO", align:"center", sortable: false },
        { text: "Ver", value: "actions", align:"center", sortable: false }
        
      ]
    }
  },
  methods: {
    getRequestData ( page, pageSize, estado, codigo, proveedor, empresa ) {
      let data = {}
      if (page) {data['page'] = page -1}
      if (pageSize) {data['size'] = pageSize}
      if (empresa) {data['empresa'] = empresa} else {data['empresa'] = 'AGROINDUSTRIAL EL PAICO S.A.'}
      if (estado) {data['estado'] = estado}
      if (codigo) {data['codigo'] = codigo}
      if (proveedor) {data['proveedor'] = proveedor}
      return data
    },
    async getcompras () {
      const data = this.getRequestData(
        this.page,
        this.pageSize,
        this.estado, 
        this.codigo, 
        this.proveedor, 
        this.empresa
        )
      CostDataService.getCompras(data)
      .then((response) => {
        const {
            lineas_OC,
            totalPages,
            currentItems,
            totalItems
          } = response.data;
          this.oc_s = lineas_OC.map(this.mapData);
          this.totalPages = totalPages;
          this.currentItems = currentItems;
          this.totalItems = totalItems;
      })

    },
    handlePageChange(value) {
      this.page = value;
      this.getcompras();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getcompras();
    },
    mapData(docs) {
      return {
        OC:docs.NUM_OC,
        LINEA_OC:docs.LINEA_OC,
        ESTADO: docs.ESTADO_OC,
        FECHA_OC: docs.FECHA,
        F_ENTREGA: docs.ETA_,
        F_RECEPCION: docs.FEC_REC,
        CODIGO: docs.COD_PROD,
        DESC_CODIGO: docs.DESC_PROD,
        CANTIDAD: docs.ART_PEDIDO,
        COSTO: Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(docs.COSTO_PEDIDO)
      }
    }
  },
  mounted() {
    this.getcompras()
  }
  
}
</script>